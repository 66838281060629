body {
  margin: 0;
  font-family:"Helvetica Neue Bold", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#FFFDD0;
  line-height: 1.4rem;
}



#root {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "HelveticaNeue-Bold",
    "Helvetica Neue Bold","Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #E1B612;
  background-color: #37393C;
}